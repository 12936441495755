<template>
    <div>
        <div class="das_top_cont">
            <div class="history_box">
                <div class="title flex_center_between_box">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../assets/img/qianqian.png" />
                        Quota
                    </div>
                    <div class="c_d_buttom flex_box">
                        <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" :header="title" name="quota.xls">
                            <button class="btn_blue btn_blu flex_center" @click="exportCompany">
                                <img src="../../assets/img/search/export_ic1.png" />
                                Export
                            </button>
                        </download-excel>
                        <button class="btn_blue flex_center" @click="addQuto">
                            <img src="../../assets/img/account/jia (2).png" />
                            Add
                        </button>
                    </div>
                </div>
                <div class="right_cont_box">
                    <div class="top_tab">
                        <div class="table_box">
                            <el-table ref="quoteList" :data="quoteList" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No Data</template>

                                <el-table-column label="Account" prop="customerName" align="left" width="400" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Available quota" prop="afterA" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Used quota" prop="amountActual" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Issue date" prop="createTime" align="left" show-overflow-tooltip :resizable="false" />

                                <!-- <el-table-column label="Operation" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <div class="flex_center">
                                            <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="Edit" placement="top">
                                                <div class="oper_down" @click="hanldeDetails(scope.row)">
                                                    <img src="../../assets/img/account/bianji.png" />
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column> -->
                            </el-table>
                            <div class="page_i_box">
                                <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum}} results</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :append-to-body="true" :visible.sync="timesDetailDialog" center :close-on-click-modal="false" custom-class="dialog_normal">
            <div class="detail_cont">
                <div class="popTitle">{{ dialogTitle }}</div>
                <div class="detail_pop_list">
                    <el-form ref="form" :model="form" label-width="80px">
                
                        <!-- Quota Allocation -->
                        <template v-if="userRole">
                            <el-form-item label="Account:">
                            <el-select v-model="form.Account" placeholder="">
                                <el-option :label="item.email" :value="item.userCode" v-for="(item, index) in accOptions" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                            <el-form-item label="Available quota:">
                                <el-input v-model="form.AvailableQuota" type="number"></el-input>
                            </el-form-item>
                        </template>
                        <!-- Quota Request -->
                        <template v-if="!userRole">
                            <el-form-item label="Account:">
                                <el-input v-model="form.Account" ></el-input>
            
                        </el-form-item>
                            <el-form-item label="Role status:">
                                <el-radio-group v-model="form.status" @input="change($event)">
                                    <el-radio label="0">In Proportion</el-radio>
                                    <el-radio label="1">Custom</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="Custom" label="User defined quota:">
                                <el-input v-model="form.integral" type="number"></el-input>
                            </el-form-item>
                        </template>
                    </el-form>
                </div>
                <div class="txt_right">
                    <button class="btn_export" type="primary" @click="onSubmit">Confirm</button>
                    <button class="btn_export btn_cancel" @click="btnCancel">Cancel</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { getOrdersPayList, editUserBalan, applyUserBalan, getUserList } from '@/api/userApi.js';
import { mixins1 } from '@/mixins/index'
import Page from '@/components/page';
export default {
    components: { Page },
    mixins: [mixins1],
    data() {
        return {
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            form: {
                Account: '',
                AvailableQuota: '',
                status: '0',
                UserQuota: 1000,
                integral: '',
            },
            Custom: false,
            accOptions: [],
            edit: false,
            timesDetailDialog: false,
            quoteList: [],
            userRole: true,
            dialogTitle: 'Quota Allocation', //Quota Request
            // 下载
            title: 'Company',
            json_fields: {
                Account: 'customerName',
                'Available Quota': 'afterA',
                'Used Quota': 'amountActual',
                'Issue date': 'createTime',
            },
            DetailsForm: [],
        };
    },
    created() {
        this.getConyentData();
    },
    methods: {
        async getConyentData() {
            // if(user.type=='2'){
            //   this.userRole=true
            // }else{
            //   this.userRole=false
            // }
            let params = { page: this.page.pageNo, limit: this.page.pageSize ,searchType:8};
            getOrdersPayList(params).then(result => {
                let data = result;
                if (data && data.code) {
                    // console.log('OrdersPay', data);
                    this.page.total = data.data.total;
                    this.quoteList = data.data.rows;
                }else{
                  this.quoteList=[]
                }
            });
            let userlist = await getUserList({ pageNum: this.page.pageNo, pageSize: this.page.pageSize });
            if (userlist.code) {
                // this.page.total = userlist.data.total;
                this.accOptions = userlist.data.rows;
            } else {
                this.accOptions = [];
            }
        },
        change(value) {
            if (value == '1') {
                this.Custom = true;
            } else {
                this.Custom = false;
            }
        },
        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.getConyentData();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.getConyentData();
        },
        //导出按钮
        exportCompany() {
            this.DetailsForm = this.quoteList;
        },
        addQuto() {
        //   console.log(JSON.parse(localStorage.getItem('userInfo')).userInfo);
            if (JSON.parse(localStorage.getItem('userInfo')).userInfo.type == '2') {
                // if(user.type=='2'){
                
                  this.edit = true
                  this.userRole = true;
                // }
            } else {
                this.form.Account= JSON.parse(localStorage.getItem('userInfo')).userInfo.email
              this.edit = false;
             
                this.userRole = false;
            }
            // this.userRole = true;
            this.timesDetailDialog = true;
        },
        hanldeDetails(row) {
            this.edit = true;
            // console.log(JSON.parse(localStorage.getItem('userInfo')).userInfo.type);
            this.timesDetailDialog = true;
        },
        onSubmit() {
        //   console.log(this.form);
            const user = JSON.parse(localStorage.getItem('userInfo')).userInfo;
            if (this.edit) {
                editUserBalan({
                    userCodeFather: user.userCode,
                    userCode: this.form.Account,
                    integral: Number(this.form.AvailableQuota)
                }).then(res => {
                    if (res.code) {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                        this.getConyentData()
                        this.timesDetailDialog = false;
                        this.$emit('upadteQuota',true)
                        
                    } else {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'error',
                        });
                    }
                });
            } else {
                let par = {
                  status:'',
                    userCodeFather: user.userCode,
                    userCode: this.form.Account,
                    integral: Number(this.form.integral)
                };
                applyUserBalan(par).then(res => {
                    if (res.code) {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                        this.timesDetailDialog = false;
                        this.$emit('upadteQuota',true)
                    } else {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'error',
                        });
                    }
                });
            }
            getOrdersPayList({ page: this.page.pageNo, limit: this.page.pageSize })
    
        },
        btnCancel() {
            this.form = {
                Account: '',
                AvailableQuota: '',
                status: '',
                UserQuota: '',
                integral: '',
            };
            this.timesDetailDialog = false;
        },
    },
};
</script>
<style scoped lang="less">
/* 日历大小 */
.el-range-editor.el-input__inner {
    width: 224px !important;
    padding: 3px 3px;
}
.el-date-editor /deep/ .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #c0c4cc;
    /* float: left; */
    line-height: 32px;
    position: relative;
    left: 47%;
}
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.country_box {
    margin-top: 60px;
}
.country_box .title {
    margin-bottom: 0;
}
.history_box {
    // margin-top: 22px;
}

.title {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 24px;
    margin-right: 8px;
}
.c_d_buttom {
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 0;
}
.btn_blue {
    width: 140px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}
.btn_blu {
    margin-right: 20px;
}
.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.his_sel_box .sel_box /deep/ .el-input__inner {
    border-color: #8497ab;
    border-radius: 8px;
}
.his_sel_box .sel_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab;
    font-weight: 500;
}
.das_top_cont {
    // margin-top: 50px;
}
.das_top_item {
    position: relative;
    width: 230px;
    height: 232px;
    padding: 20px 20px 10px;
    border-radius: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.das_top_item .item_img {
    position: absolute;
    left: 95px;
    top: -20px;
    width: 40px;
    height: 40px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
}
.das_top_item .color1 {
    background: #1290c9;
}
.das_top_item .color2 {
    background: #f56b6b;
}
.das_top_item .color3 {
    background: #55b419;
}
.das_top_item .color4 {
    background: #ff7600;
}
.das_top_item .item_img img {
    width: 18px;
    height: 18px;
}
.das_top_item .item_title {
    font-size: 18px;
    color: #022955;
    margin: 10px 0 10px;
    text-align: center;
}
.das_top_item .item_group {
    margin-bottom: 10px;
    font-size: 14px;
}
.das_top_item .item_lab {
    display: block;
    color: #8497ab;
    margin-bottom: 8px;
}
.top_tab {
    margin-top: 40px;
}

.result_tab {
    padding: 20px;
}
.flex__box {
    display: flex;
    align-items: center;
}

.oper_down img {
    width: 18px;
    height: 16px;
}

.detail_pop_list {
    margin: 40px auto 0;
}
.detail_pop_title_box {
    border-bottom: 1px solid #e7eaed;
    padding-bottom: 10px;
}
.detail_pop_item {
    border-bottom: 1px solid #e7eaed;
    padding: 15px 0;
}
.detail_pop_txt,
.detail_pop_title {
    width: 33.3%;
    text-align: center;
    font-size: 16px;
}
.detail_pop_title {
    color: #8497ab;
}
.detail_pop_txt {
    color: #022955;
}
.txt_right {
    text-align: right;
    margin-top: 15px;
}
.sel_box {
    margin-right: 15px;
}
.detail_cont {
    /deep/.el-select {
        display: inline-block;
        position: relative;
        width: 100%;
    }
}
.popTitle {
    height: 24px;
    font-size: 20px;
}
.txt_right {
    //   margin-left: -150px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
/deep/.el-form-item__label {
    width: 150px !important;
    text-align: left;
}

/deep/.el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;

    margin-left: 150px !important;
}
/deep/.el-form-item__label {
    color: #022955ff;
}
</style>